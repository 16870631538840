import type { BaseInstanceConfig } from './models/BaseInstanceConfig';

type Subdomain = Record<string, Partial<BaseInstanceConfig>>;

const generateDefaultDomainsForInstance = (instance: string): string[] => {
  return [
    `${instance}.whitelabel.imxplatform.de`, // (default) production environment
    `main.${instance}.whitelabel.imxplatform.de`, // test environment
    `${instance}-whitelabel.infomax.dev`, // development environment
  ];
};

const INSTANCE_TO_DOMAINS: Record<string, (string | Subdomain)[]> = {
  demo: [
    'whitelabel.imxplatform.de', // production environment
    'storybook-whitelabel.imxplatform.de', // production environment
    'main.whitelabel.imxplatform.de', // test environment
    'main.storybook-whitelabel.imxplatform.de', // test environment
    'whitelabel.infomax.dev', // development environment
    'widget-whitelabel.infomax.dev', // development environment
    'storybook-whitelabel.infomax.dev', // development environment
  ],
  fwtm: [...generateDefaultDomainsForInstance('fwtm')],
  hht: [...generateDefaultDomainsForInstance('hht')],
  nrwt: [...generateDefaultDomainsForInstance('nrwt')],
  rnd: [
    ...generateDefaultDomainsForInstance('rnd'),
    {
      'events.rnd.de': {
        defaultWidgetId: 5,
      },
    },
    {
      'events.kn-online.de': {
        defaultWidgetId: 4,
        useBasicAuth: true,
      },
    },
  ],
  tash: [...generateDefaultDomainsForInstance('tash')],
};

export const DOMAIN_TO_CONFIG_MAP: Map<
  string,
  Partial<BaseInstanceConfig> & { instanceId: string }
> = new Map();

Object.entries(INSTANCE_TO_DOMAINS).forEach(([instanceId, domains]) => {
  domains.forEach((domain) => {
    if (typeof domain === 'string') {
      DOMAIN_TO_CONFIG_MAP.set(domain, { instanceId });
    } else {
      Object.entries(domain).forEach(([domainName, config]) => {
        DOMAIN_TO_CONFIG_MAP.set(domainName, { instanceId, ...config });
      });
    }
  });
});
