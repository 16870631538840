import type { Nullable } from '@models/CustomUtilityTypes';
import type { RouterConfig } from '@nuxt/schema';
import type {
  RouteLocationNormalized,
  RouteLocationNormalizedLoaded,
} from 'vue-router';

type ScrollPosition = Nullable<{
  behavior?: ScrollOptions['behavior'];
  left: number;
  top: number;
}>;

export default {
  scrollBehavior(
    to: RouteLocationNormalized,
    from: RouteLocationNormalizedLoaded,
    savedPosition: ScrollPosition
  ) {
    if (isEmpty(from.meta)) {
      // do not do any scrolling, if we are coming from a redirect
      return undefined;
    }

    const scrollStore = useScrollStore();

    // 1. Check for our custom saved position
    const customSavedPosition = scrollStore.getPosition(
      to.name as string,
      to.params as Record<string, string>
    );

    if (customSavedPosition) {
      return new Promise((resolve) => {
        const onContentLoaded = () => {
          window.removeEventListener('content-loaded', onContentLoaded);
          resolve({
            top: customSavedPosition.y,
            behavior: 'smooth',
          });
        };
        window.addEventListener('content-loaded', onContentLoaded);
      });
    }

    // 2. Use browser's native savedPosition if available (back/forward navigation)
    if (savedPosition) {
      return savedPosition;
    }

    // 3. Default behavior - scroll to top
    return {
      top: 0,
      behavior: 'instant',
    };
  },
} satisfies RouterConfig;
