import type { MediaFilter, SortOrder } from '@gql/schema';
import type { Accessor } from './accessor';
import type { BaseAddress } from './BaseAddress';
import type { BaseCoords } from './BaseCoords';
import type { BaseTourDifficulty } from './BaseTourDifficulty';
import type { WhlConsentManagerType } from './WhlConsentManagerType';
import type { BaseLocationType } from './BaseLocation';

export interface BaseApiMediaFilterArguments {
  filter?: MediaFilter;
  fallback?: MediaFilter[];
  sortOrder?: SortOrder;
}

export enum ContactType {
  CONTRIBUTOR = 'contributor',
  CONTACT = 'contact',
}

/** id of entity to iconify icon */
export type IconMapping = Record<number, string>;

/** value of tour difficulty to BaseTourDifficulty */
export type TourDifficultyMapping = Record<number, BaseTourDifficulty>;

export type OpeningHoursConfiguration = {
  hideDateRangeInfo?: boolean;
};

export interface HeroConfiguration {
  displayFallbackImage?: boolean;
  showCategory?: boolean;
  categoryBlacklistRegex?: RegExp;
  showContactAddress?: boolean;
}

export interface StandardListConfiguration {
  displayFallbackImage?: boolean;
  showCategory?: boolean;
  categoryBlacklistRegex?: RegExp;
  showContactAddress?: boolean;
}

export interface SliderTeaserConfiguration {
  displayFallbackImage?: boolean;
  showCategory?: boolean;
  categoryBlacklistRegex?: RegExp;
  showContactAddress?: boolean;
}

export interface BaseComponentTypesConfiguration {
  hero?: BaseApiMediaFilterArguments & HeroConfiguration;
  gallery?: BaseApiMediaFilterArguments;
  list?: BaseApiMediaFilterArguments & {
    standard?: StandardListConfiguration;
  };
  slider?: {
    teaser?: SliderTeaserConfiguration;
  };
  article?: {
    contact?: ContactType;
    showInfoAttributes?: boolean;
    showContributor?: boolean;
  };
}

export interface BaseEventTypeConfiguration {
  customFallbackIcon?: string;
  detail?: BaseComponentTypesConfiguration;
  list?: BaseComponentTypesConfiguration;
  overview?: BaseComponentTypesConfiguration;

  customCategoryFallbackIcons?: Record<number, string>;
}

export interface BasePoiTypeConfiguration {
  detail?: BaseComponentTypesConfiguration;
  list?: BaseComponentTypesConfiguration;
  overview?: BaseComponentTypesConfiguration;
  /**
   * Icon mapping for productlines (id => iconify icon)
   */
  productlineIcons?: IconMapping;
  openingHours?: OpeningHoursConfiguration;
}

export interface BaseTourTypeConfiguration
  extends BaseComponentTypesConfiguration {
  /**
   * Icon mapping for the tour categories (id => iconify icon)
   */
  categoryIcons?: IconMapping;
  difficultyMapping?: TourDifficultyMapping;
}

interface BasePageheaderSearchTypeConfiguration {
  /**
   * Configuration for the pageheader search object type.
   */
  locationSearchWhitelist: BaseLocationType[];
}

export interface BasePageheaderTypeConfiguration {
  /**
   * Configuration for the pageheader object type.
   */
  search: BasePageheaderSearchTypeConfiguration;
}

export interface MapInstanceConfiguration {
  /**
   * default coordinates when the map is initially loaded
   */
  defaultCoords: BaseCoords;

  /**
   * default map zoom level when the map is initially loaded. Default: 12
   */
  defaultZoom?: number;

  /**
   * Whether to show the weather on the map. Default: false
   */
  weatherControls?: boolean;

  /**
   * Whether to use the smartScroll function. Default: true
   */
  smartScroll?: boolean;

  /**
   * If customer has a maptoolkit api key, it can be set here to use it for the map - otherwise infomax default key is used.
   */
  maptoolkitApiKey?: string;
}

export interface RoutingConfiguration {
  /**
   * Define which routing service to use for public transport routing. Default: Google Maps
   */
  publicTransportRoutingService: RoutingService;

  /**
   * Define which routing service to use for car routing. Default: Google Maps
   */
  carRoutingService: RoutingService;

  /**
   * Define the purpose id of contact addressbases, representing the next public transport station.
   */
  publicTransportStationContactPurposeId?: number;
  publicTransportBusProductlineIds?: number[];
  publicTransportTrainProductlineIds?: number[];
}

export enum RoutingService {
  GOOGLE_MAPS,
  NAHSH,
}

export interface BaseInstanceConfig {
  useBasicAuth?: boolean;

  /** The ID of the imx.Platform (Whitelabel Widgets) instance. */
  instanceId: string;

  /** The type of the consent manager to use in standalone mode. Default: NULL (widget always has dummy consent manager) */
  portalConsentManager?: WhlConsentManagerType;

  /** Configuration for the cookie consent manager, depends on the consent manager type. */
  consentManagerConfiguration?: ConsentManagerConfig;

  /**
   * The URL of the GraphQL endpoint.
   */
  graphqlEndpoint: string;

  /**
   * The bearer token to use for authentication.
   */
  graphqlBearerToken: string;

  /**
   * The default widget id to use when no widget identifier is provided.
   */
  defaultWidgetId: number;

  /**
   * The languages that are enabled for this instance.
   */
  enabledLanguages: string[];

  /**
   * The alias to use for branding. If not provided, instanceId is used to access branding.
   */
  brandingAlias?: string;

  /**
   * The URL to the logo to use in the header.
   */
  headerLogo?: string;

  /**
   * The URL to the logo to use in the footer.
   */
  footerLogo?: string;

  /**
   * If true, use instance specific fallback images. If false, the default fallback images are used.
   *
   * Default: false
   */
  customFallbackImages?: boolean;

  /** The contact information to display in the footer. */
  footerContact: Required<BaseAddress>;

  /**
   * The default language to use when no language is provided.
   */
  map: MapInstanceConfiguration;

  /**
   * Configuration for the routing.
   */
  routing?: RoutingConfiguration;

  /**
   * Default configuration for all object types.
   */
  defaults: BaseComponentTypesConfiguration;

  /**
   * Configuration for the event object type.
   */
  event?: BaseEventTypeConfiguration;

  /**
   * Configuration for the poi object type.
   */
  poi?: BasePoiTypeConfiguration;

  /**
   * Configuration for the tour object type.
   */
  tour?: BaseTourTypeConfiguration;

  /**
   * Configuration for the pageheader object type.
   */
  pageheader: BasePageheaderTypeConfiguration;
}

export type BaseInstanceConfigAccessor = Accessor<BaseInstanceConfig>;
